//
// Name:            Footer
//
// Description:     Contains all footer styles
//
// ========================================================================
// Component: Footer
// =========================================================================
footer.footer {
    min-height: 481px;
    background-image: url('/images/footer/footer-background.webp');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    margin-top: 7rem;

    .logo img{
        max-height: 184px;
    }
    .footer-content{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        align-items: center;
        justify-content: start;
        padding-top: 65px;
        min-height: 394px;
    }

    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li a:hover{
            color: var(--color-deep-green);
        }
    }

    .links ul{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 1rem 1rem;
        li a:hover{
            text-decoration: underline;
        }
    }

    .social{
        grid-row: 2;
        grid-column: 2;
        ul{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1.5rem;
        }
    }

    .bottom{
        // Position
        grid-column: 1 / span 3;
        grid-row: 3;
        // Inside
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        justify-content: space-between;
        padding-top: 55px;
        .logos{
            display: flex;
            gap: 1rem;
            align-items: center;
            justify-content: flex-start;
        }
        img.logo-nysca{
            max-height: 50px;
        }
        img.logo-bright-hill{
            max-height: 81px;
        }

        .words{
            text-align: right;
            padding: 9px 0;
            border-bottom: 1px solid var(--color-dark-green);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 14px;
            >*:not(:last-child){
                &::after{
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: var(--color-dark-green);
                    margin-left: 1rem;
                    margin-right: 1rem;
                    transform: translateY(-.0125em);
                }
            }
        }
    }
}