//
// Name:            Header
//
// Description:     Contains all header styles
//
// ========================================================================
// Component: Header
// ========================================================================

header.header{
    min-height: 112px;
    background-color: transparent;

    > .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo img{
        height: 100%;
        max-height: 112px;
    }
    .items{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .visible-items{
        display: flex;
        gap: 1em;
        align-items: center;
        min-height: 30px;
        border-right: 2px solid var(--color-dark-green);
        padding-right: 32px;
        margin-right: 32px;
        font-weight: 600;
        font-size: 18px;
    }
}