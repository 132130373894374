//
// Name:            Base
//
// Description:     Contains all base styles, including basic resets
//
// ========================================================================
// Component
// ========================================================================

body {
    font-family: var(--font-body);
    font-weight: var(--font-body-weight);
    background-color: var(--color-background);
    color: var(--color-text);
}

::selection {
    color: var(--color-dark-green);
    background-color: var(--color-mint);
}

h1,
h2,
h3 {
    font-family: var(--font-title);
    font-weight: var(--font-title-weight);
}

.wrapper {
    max-width: var(--max-width);
    margin: 0 auto;
    width: calc(100% - 2rem);
}

h1,
.h1 {
    font-size: 6rem;
    line-height: 1.25em;

    @include breakpoint(tablet) {
        font-size: 4rem;
    }

    @include breakpoint(mobile) {
        font-size: 3rem;
    }
}

h2,
.h2 {
    font-size: 4rem;
    line-height: 1.25em;

    @include breakpoint(mobile) {
        font-size: 3rem;
    }
}

h3,
.h3 {
    font-size: 3rem;
    line-height: 1.25em;
}

h4,
.h4 {
    font-size: 2rem;
    line-height: 1.25em;
    font-weight: normal;
}

h5,
.h5 {
    font-size: 1.5rem;
    line-height: 1.25em;
    font-weight: normal;
}

.subhead {
    font-size: 1.25rem;
    line-height: 1.5em;
    font-weight: 600;

    &--small {
        font-size: 1.125em;
    }
}

p {
    font-size: 1rem;
    line-height: 1.5em;
}

.large {
    font-size: 1.125rem;
}

p.alt {
    font-size: 0.9rem;
}

.small {
    font-size: 0.75rem;
}

.show-mobile {
    display: none;
}

@include breakpoint(mobile) {
    .hide-mobile {
        display: none;
    }

    .show-mobile {
        display: inherit;
    }
}