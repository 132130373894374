//
// Component:       Variables
// Description:     Defines all color and style related values as variables
//                  to allow easy customization for the most common cases.
//
// ========================================================================
// Component: Variables
// ========================================================================

$base-font-size: 16;

// Colors
$brand-primary: #000000;
$background: #F0F0EB;
$color-offwhite: #FAFAF5;
$color-white: #FFFFFF;
$color-black: #000000;
$shadow: #fff;
$red: var(--color-warning);
$secondary-orange: red;

:root {
  --color-mint: #20FFAF;
  --color-green: #25DB9A;
  --color-dark-green: #03220F;
  --color-deep-green: #075D3E;
  --color-kelly-green: #077B51;
  --color-offwhite: #F2EFE5;
  --color-paper: #D1C7A5;
  --color-gray: #D1C7A5;
  --color-warning: #D10404;

  --color-text: var(--color-dark-green);
  --color-background: var(--color-offwhite);
}

// Fonts
:root {
  --font-title: 'Cormorant Garamond';
  --font-title-weight: '600';

  --font-body: 'Poppins';
  --font-body-weight: normal;
}

// Forms
$form-field-border-radius: 23px;
$form-field-background: $color-white;
$form-field-border: 1px solid #ddd;
$form-field-font-size: 17px;
$form-field-color: $shadow;
$form-field-padding: 0 20px;
$form-field-height: 44px;

// Layout
$wrapper-max-width-large: 1440px;
$wrapper-max-width-small: 1200px;
$breakpoints: sm 568px 568px,
  md 768px 768px,
  lg 960px 960px,
  xlg 1200px 1200px !default;
$breakpoint-sm: 568px;
$breakpoint-md: 768px;
$breakpoint-lg: 960px;
$breakpoint-xlg: 1200px;
$flexboxgrid-max-width: $wrapper-max-width-large !default;

:root {
  --max-width: 1300px;
}

@media screen and (max-width: $breakpoint-xlg) {
  :root {
    --max-width: 768px;
  }
}

@media screen and (max-width: $breakpoint-md) {
  :root {
    --max-width: 300px;
  }
}




// @include breakpoint(tablet) {
//   :root {
//     --max-width: 768px;
//   }
// }