//
// Name:            Navigation
//
// Description:     Contains all navigation styles
//
// ========================================================================
// Component: Navigation
// ========================================================================
button.hamburger{
    width: 44px;
    height: 44px;
    border: 3px solid transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: unset;
    padding: unset;
    background-image: url('/images/icons/menu.svg');
    background-position: center;
    background-repeat: no-repeat;
    &.opened{
        background-image: url('/images/icons/close.svg');
    }
}

#mainNav{
    display: none;
    &.opened{
        display: block;
    }
}