main#single-author {}

#author-bio {
    background-color: var(--color-dark-green);
    color: var(--color-mint);
    border-radius: 1.25rem;
    width: calc(100vw - 2rem);
    margin: 0 auto;
    max-width: 83.75rem;
    padding: 3.5rem 1.5rem;
    margin-top: 3rem;
    margin-bottom: 8.13rem;

    .wrapper {
        --max-width: 75rem;
    }

    hr {
        width: 100%;
        border: none;
        height: 0.25rem;
        background-color: var(--color-mint);
        margin-bottom: 3.5rem;
    }

    h2 {
        margin-bottom: .75em;
    }

    h3 {
        @extend .subhead;
        font-family: var(--font-body);
        font-weight: 600;
    }

    .bio h3 {
        border-bottom: 1px solid var(--color-mint);
        padding-bottom: .75rem;
        margin-bottom: .75rem;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6.38rem;

        @include breakpoint(tablet) {
            gap: 1rem;
        }

        @include breakpoint(mobile) {
            gap: 2rem;
            grid-template-columns: 1fr;
        }
    }

    ul.locations {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 3rem;
        flex-wrap: wrap-reverse;

        li {
            position: relative;
        }

        li:not(:last-child)::after {
            position: absolute;
            content: '⏺';
            width: 3rem;
            text-align: center;
            right: -3rem;
            pointer-events: none;
        }
    }
}

#author-timeline {
    hr {
        width: 100%;
        height: 0.25rem;
        background-color: var(--color-dark-green);
        border: none;
        margin-bottom: 5.75rem;
    }

    .timeline {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3.5rem 6.25rem;

        @include breakpoint(tablet) {
            gap: 2rem 1.5rem;
        }

        @include breakpoint(mobile) {
            grid-template-columns: 1fr;
            gap: 2rem;
        }
    }

    .dates {
        @extend h5;
        background-color: var(--color-paper);
        border-radius: 0.63rem;
        padding: .5rem 1rem;
        display: inline-block;
        margin-bottom: 2rem;
    }

    h3 {
        font-family: var(--font-body);
        font-size: 1.5rem;
        font-weight: 600;
    }

    p {
        font-size: 1.125rem;
    }

    margin-bottom: 7.5rem;
}

#author-contact {
    background-color: var(--color-dark-green);
    color: var(--color-mint);
    mask-image: url('/images/single-author/bio-contact-mask.svg');
    mask-repeat: no-repeat;
    mask-position: center center;
    mask-size: 100% 100%;
    min-height: 579px; // TODO: make this responsive


    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem 8rem;
    }
}